<template>
  <b-card>
    <h4 class="mb-0">
      Edit Ekstrakulikuler
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <b-form-group label="Tahun Akademik" label-for="name">
              <validation-provider #default="{ errors }" name="Tahun Akademik" rules="required">
                <b-form-input id="academic_year_id" v-model="dataStudent.academic_year_id" :state="errors.length > 0 ? false : null"
                  placeholder="Tahun Akademik" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Type" label-for="extracurricular_type_id">
              <validation-provider #default="{ errors }" name="NISN" rules="required">
                <b-form-input id="extracurricular_type_id" v-model="dataStudent.extracurricular_type_id" :state="errors.length > 0 ? false : null"
                  placeholder="Type" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Hari" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Hari" rules="required">
                <b-form-input id="day" v-model="dataStudent.day"
                  :state="errors.length > 0 ? false : null" placeholder="Hari" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Jam" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Jam" rules="required">
                <b-form-input id="hour" v-model="dataStudent.hour"
                  :state="errors.length > 0 ? false : null" placeholder="Jam" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Kategori" label-for="type">
              <validation-provider #default="{ errors }" name="type" rules="required">
                <b-form-input id="type" v-model="dataStudent.hour"
                  :state="errors.length > 0 ? false : null" placeholder="Kategori" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          
          <!-- <b-col md="6">
            <b-form-group label="Provinsi" label-for="province">
              <validation-provider
                #default="{ errors }"
                name="Provinsi"
                rules="required"
              >
                <v-select
                  :reduce="(province) => province.name"
                  id="province"
                  v-model="locations.province"
                  :options="dataProvinces"
                  label="name"
                  placeholder="Pilih Provinsi"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="6">
            <b-form-group label="Kabupaten/Kota" label-for="city">
              <validation-provider
                #default="{ errors }"
                name="Kabupaten/Kota"
                rules="required"
              >
                <v-select
                  :reduce="(city) => city.name"
                  id="city"
                  v-model="locations.city"
                  :options="filterCities"
                  label="name"
                  placeholder="Pilih Kabupaten/Kota"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-container> -->

    <!-- </b-container> -->
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    dataCountries,
    dataProvinces,
    dataCities,
    dataDistrics,
    dataSubDistrics,
    required,
    name: '',
    dataStudent: {
      nisn: '',
      name: '',
      birth_place: '',
      birth_date: '',
      gender: '',
      religion: '',
      nationality: '',
    },
    locations: {
      province: '',
      city: '',
      district: '',
      subdistrict: '',
      father_province: '',
      mother_province: '',
      father_city: '',
      mother_city: '',
      father_district: '',
      mother_district: '',
      father_subdistrict: '',
      mother_subdistrict: '',
    },
    dataGender: [
      { value: 'M', text: 'Laki-laki' },
      { value: 'F', text: 'Perempuan' },
    ],
    dataReligion: [
      { value: 1, text: 'Islam' },
      { value: 2, text: 'Protestan' },
      { value: 3, text: 'Katolik' },
      { value: 4, text: 'Hindu' },
      { value: 5, text: 'Buddha' },
      { value: 6, text: 'Khonghucu' },
    ],
  }),
  computed: {
    filterCities() {
      const findCity = this.dataCities.filter(
        city => city.name === this.locations.city,
      )
      console.log(findCity[0])
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
    filterCitiesFather() {
      return this.dataCities.filter(
        item => item.parent === this.locations.father_province.wilayah_id,
      )
    },
    filterCitiesMother() {
      return this.dataCities.filter(
        item => item.parent === this.locations.mother_province.wilayah_id,
      )
    },
    filterDistricsFather() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.father_city.wilayah_id,
      )
    },
    filterDistricsMother() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.mother_city.wilayah_id,
      )
    },
    filterSubDistricsFather() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.father_distric.wilayah_id,
      )
    },
    filterSubDistricsMother() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.mother_distric.wilayah_id,
      )
    },
  },
  created() {
    this.getStudentDetail()
  },
  methods: {
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/students/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.academic_year_id = data.academic_year_id
      this.dataStudent.extracurricular_type_id = data.extracurricular_type_id
      this.dataStudent.day = data.day
      this.dataStudent.hour = data.hour
      this.dataStudent.type = data.type
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            Object.keys(this.locations).forEach(key => {
              dataFormStudent.append(key, this.locations[key])
            })
            this.$http.put(`/extracurricularinstructors/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            // alertnya()
          } catch (err) {
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
